import { render, staticRenderFns } from "./IconTranslate.vue?vue&type=template&id=5f68cdc2&"
import script from "./IconTranslate.vue?vue&type=script&lang=js&"
export * from "./IconTranslate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f68cdc2')) {
      api.createRecord('5f68cdc2', component.options)
    } else {
      api.reload('5f68cdc2', component.options)
    }
    module.hot.accept("./IconTranslate.vue?vue&type=template&id=5f68cdc2&", function () {
      api.rerender('5f68cdc2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/Icons/Others/IconTranslate.vue"
export default component.exports